import React, { useState } from 'react';
import './../i18n.js';
import { useTranslation } from 'react-i18next';
import Home1_banner from "./banner/Home1_banner";
import Header  from './layout/Header';
import Home3_mainpage from './Home3_mainpage';

export const Home3 = (props) => {
  const { t } = useTranslation(); 
  
  
   return (
     <div className="page">      
        <Header />
        <Home1_banner data={t}/>
        <Home3_mainpage data={t}/> 
     </div> 
   );
}


export default Home3;
