import React, { Component, Suspense, useState }from 'react'
import  Menu from './menu';
import Mobilemenu from './Mobile_menu';
import { withTranslation, useTranslation } from 'react-i18next';

const languages = [
    { value: 'en', text: "English" },
    { value: 'fr', text: "French" }
];

export const Flags = (props) => {
     
        const [select, setSelect] = useState(false);
        
        const { t, i18n } = useTranslation(); 

        const handleLang = (lang) => {
            if(select){
                i18n.changeLanguage(lang);
                setSelect(false);
            }else{
                setSelect(true);
            }
        }

        return (
                <div>
                    {(select || i18n.language=="en") && <a class="flags" onClick={() => handleLang("en")} ><img width="40" src="/i18n/flags/en-40.png" alt=""/></a>} 
                    {(select || i18n.language=="fr") && <a  class="flags" onClick={() => handleLang("fr")} ><img  width="40" src="/i18n/flags/fr-40.jpg" alt=""/></a>} 
                </div>          
        )
    
}

export default Flags;
