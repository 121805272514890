import React from 'react';


export default function Success () {

  return (
    <div>

                        <div style={{width: "100%", background: "#90ee90", height:"30px"}}>

                        <p style={{marginLeft: "2%", marginTop: "2px"}}><img src="./images/tick.png" height="18px" style={{marginRight: "10px"}}/> Message envoyé avec succès !</p>
                        
                        </div>


    </div>
  );
};