import React, { Component, useState } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem,  MDBCollapse, MDBDropdown, MDBDropdownItem,
MDBHamburgerToggler } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import {  useTranslation } from 'react-i18next';
import Flags from './Flags';

export const Mobilemenu = () => {
//class Mobilemenu extends Component {
  const [collapseID, setCollapseID] = useState('');
  const [collapse, setCollapse] = useState({collapse1: false});

  const { t, i18n } = useTranslation(); 


const toggleCollapse = cID => () => {
  setCollapseID(prevState => ({ collapseID: (prevState !== cID ? cID : '') }));
}

const toggleSingleCollapse = collapseId => {
  setCollapse(state => ({...state, [collapseId]: !state[collapseId]}));
}

  return (
    <Router>
        <MDBNavbar>
            <MDBHamburgerToggler id="hamburger1" onClick={()=> toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={collapse?.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <MDBDropdown>
                        <MDBDropdownItem href={ '/'}>
                            <span >D-SAP</span>
                        </MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownItem href={ '/data_structuring'}>
                            <span>{t('Services')}</span>
                        </MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownItem href={ '/single_style2'}>
                            <span>{t('Realisations')}</span>
                        </MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  {/* <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownItem href={ '/blog_grid'}>
                            <span>{t('Actualite')}</span> 
                        </MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem> */}
                  <MDBNavItem>
                    <MDBDropdown>
                        <MDBDropdownItem href={ '/faq'}>
                            <span>FAQs</span>
                        </MDBDropdownItem>
                    </MDBDropdown>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBDropdown>
                      <Flags />
                    </MDBDropdown>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
          </MDBNavbar>
    </Router>
    );  
}

export default Mobilemenu;